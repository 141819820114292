import React from "react";
import PropTypes from "prop-types";

const GridHeader = ({ className, children, gridHeaderRef }) => {
  return (
    <div className={`grid-header ${className}`} ref={gridHeaderRef}>
      {children}
    </div>
  );
};

GridHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

GridHeader.defaultProps = {
  className: "",
};

export default GridHeader;
