import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const GlossaryItem = ({ title, value }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-6 glossary-item">
      <div className="font-semibold text-blue-1200 mb-1">{title}</div>
      {_.isString(value) ? (
        <div className="mb-12" dangerouslySetInnerHTML={{ __html: value }} />
      ) : (
        <table className="glossary">
          <thead>
            <tr>
              <th>{t("labels.implications_for")}</th>
              <th>{t("labels.strong_recommendation")}</th>
              <th>{t("labels.conditional_recommendation")}</th>
            </tr>
          </thead>
          <tbody>
            {_.map(value, (v, key) => (
              <tr key={key}>
                <td className="text-center">{v.title}</td>
                <td>{v.strong_recommendation}</td>
                <td>{v.conditional_recommendation}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

const Glossary = () => {
  const { t } = useTranslation();

  const glossary = t("glossary", { returnObjects: true });

  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <div className="w-full flex flex-row align-center justify-between mb-5 mr-5">
        <h1 className="pl-15 py-2 page-title--glossary text-2xl text-blue-900 font-semibold">
          {t(`labels.glossary`)}
        </h1>
      </div>
      <div className="px-6">
        {_.map(glossary, (glossaryItem, key) => (
          <GlossaryItem key={key} title={glossaryItem.title} value={glossaryItem.value} />
        ))}
      </div>
    </div>
  );
};

export default Glossary;
