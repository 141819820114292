import { first, some } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  guidelineShape,
  interventionShape,
  noteShape,
  populationShape,
  questionShape,
  referenceShape,
} from "../../lib/data_shapes";
import ExpandableCard from "../common/ExpandableCard";
import QuestionsData from "../common/QuestionsData";
import Remarks from "../common/Remarks";
import RecommendationHeader from "../recommendation/RecommendationHeader";
import SourceOfRecommendation from "../recommendation/SourceOfRecommendation";
import Notes from "./Notes";

const questionsHasCommonStrengthAndCertainty = (questions) => {
  if (questions.length < 2) return true;
  const baseQuestion = first(questions);
  const { gradeStrength: baseStrength, gradeCertaintyOfEvidence: baseCertainty } = baseQuestion;

  return !some(
    questions,
    ({ gradeStrength, gradeCertaintyOfEvidence }) =>
      gradeStrength !== baseStrength || gradeCertaintyOfEvidence !== baseCertainty
  );
};

const Recommendation = ({ document }) => {
  const {
    question,
    recommendation,
    intervention,
    population,
    guideline,
    note,
    reference,
    recommendationIntent,
  } = document;

  const commonStrengthAndCertainty = questionsHasCommonStrengthAndCertainty(question);
  const { t } = useTranslation();
  return (
    <>
      <RecommendationHeader
        commonStrengthAndCertainty={commonStrengthAndCertainty}
        question={first(question)}
        intents={recommendationIntent}
        recommendation={recommendation.value}
      />
      <div className="separator my-3" />
      <Remarks remarks={recommendation.remarks} />
      {!commonStrengthAndCertainty && (
        <ExpandableCard title={t("labels.comparisons")} className="mb-5">
          <QuestionsData
            commonStrengthAndCertainty={commonStrengthAndCertainty}
            interventions={intervention}
            populations={population}
            questions={question}
          />
        </ExpandableCard>
      )}
      {commonStrengthAndCertainty && (
        <QuestionsData
          commonStrengthAndCertainty={commonStrengthAndCertainty}
          interventions={intervention}
          populations={population}
          questions={question}
        />
      )}
      <SourceOfRecommendation guideline={guideline} />
      <Notes notes={note} references={reference} />
      <ReactTooltip
        className="tooltip-container"
        type="dark"
        effect="float"
        backgroundColor="#031B49"
      />
    </>
  );
};

Recommendation.propTypes = {
  document: PropTypes.shape({
    guideline: guidelineShape,
    interventions: PropTypes.arrayOf(interventionShape),
    populations: PropTypes.arrayOf(populationShape),
    questions: PropTypes.arrayOf(questionShape),
    notes: PropTypes.arrayOf(noteShape),
    references: PropTypes.arrayOf(referenceShape),
  }).isRequired,
};

export default Recommendation;
