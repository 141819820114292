import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import QuestionData from "./QuestionData";

const QuestionsData = ({ commonStrengthAndCertainty, questions, interventions, populations }) => {
  return (
    <div>
      {map(questions, question => (
        <QuestionData
          commonStrengthAndCertainty={commonStrengthAndCertainty}
          interventions={interventions}
          key={question["@id"]}
          populations={populations}
          question={question}
        />
      ))}
    </div>
  );
};

QuestionsData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool.isRequired,
  interventions: PropTypes.arrayOf(interventionShape).isRequired,
  populations: PropTypes.arrayOf(populationShape).isRequired,
  questions: PropTypes.arrayOf(questionShape.isRequired)
};

export default QuestionsData;
