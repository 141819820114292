import React from "react";
import { find } from "lodash";
import PropTypes from "prop-types";
import Select from "./Select";

const FilterSelect = ({ label, onChange, options, selected, className, labelClassName }) => {
  // need to check if selected value exists in options, otherwise it will crash
  const selectedExists = find(options, option => option.value === selected);
  return (
    <div className={`filter-select mb-3 ${className}`}>
      <label className={`ml-1 block text-white font-bold font-sans ${labelClassName}`}>
        {label}
      </label>
      <Select
        listClassName="filter-options"
        onChange={onChange}
        options={options}
        selected={selectedExists !== undefined ? selected : null}
      />
    </div>
  );
};

FilterSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  selected: PropTypes.string
};

FilterSelect.defaultProps = {
  className: "",
  labelClassName: "",
  selected: null
};

export default FilterSelect;
