import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { questionShape } from "../../lib/data_shapes";
import Box from "../common/Box";
import Intents from "../common/Intents";
import RecommendationCertainty from "./RecommendationCertainty";
import RecommendationStrength from "./RecommendationStrength";

const RecommendationHeader = ({
  commonStrengthAndCertainty,
  intents,
  recommendation,
  question,
}) => {
  const { t } = useTranslation();

  const {
    gradeStrength,
    gradeCertaintyOfEvidence,
    gradeCertaintyOfEvidenceLabel,
    recommendationDirection,
  } = question;

  return (
    <div>
      <div className="flex flex-row">
        <div className="flex-grow">
          <h1 className="text-teal-100 text-xl font-bold">{t("recommendation.title")}</h1>
          <Intents intents={intents} />
        </div>
        <div>
          <div className="flex flex-col md:flex-row">
            {commonStrengthAndCertainty ? (
              <>
                <RecommendationStrength
                  withIcon
                  gradeStrength={gradeStrength}
                  recommendationDirection={recommendationDirection}
                  textClassName="text-base"
                />
                <RecommendationCertainty
                  gradeCertaintyOfEvidence={gradeCertaintyOfEvidence}
                  gradeCertaintyOfEvidenceLabel={gradeCertaintyOfEvidenceLabel}
                  signClassName="quality-sign--yellow"
                />
              </>
            ) : (
              <Box
                className="mb-1 w-64 text-center mr-2 flex-grow-0"
                contentClassName="font-normal"
              >
                <div>{t(`recommendation.detailed_strength_and_certainty`)}</div>
              </Box>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="font-semibold mr-6" dangerouslySetInnerHTML={{ __html: recommendation }} />
      </div>
    </div>
  );
};

RecommendationHeader.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool.isRequired,
  recommendation: PropTypes.string.isRequired,
  question: questionShape,
  intents: PropTypes.arrayOf(PropTypes.string),
};

RecommendationHeader.defaultProps = {
  question: {},
};

export default RecommendationHeader;
