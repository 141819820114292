import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const RowValue = ({ label, value }) => {
  const { t } = useTranslation();
  return label.toLowerCase() === "url" ? (
    <a className="external-link" href={value} target="_blank" rel="noreferrer noopener">
      {t("actions.go_to_website")}
    </a>
  ) : (
    value
  );
};

const CustomTable = ({ className, labelClassName, rowClassName, rows, valueClassName }) => {
  return (
    <div className={`font-catamaran ${className}`}>
      {_.compact(rows).map((row) =>
        !_.isEmpty(row.value) ? (
          <div
            key={_.kebabCase(row.label)}
            className={`custom-table flex flex-col sm:flex-row ${rowClassName}`}
          >
            <div
              className={`custom-table__label py-1 px-2 sm:py-3 sm:px-3 text-blue-400 font-semibold
            ${labelClassName}`}
            >
              {row.label}
            </div>
            <div
              className={`custom-table__value py-1 px-2 mb-2 sm:mb-0 sm:py-3 sm:px-3
            ${valueClassName}`}
            >
              <RowValue label={row.label} value={row.value} />
            </div>
          </div>
        ) : null
      )}
    </div>
  );
};

CustomTable.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ),
  valueClassName: PropTypes.string,
};

CustomTable.defaultProps = {
  className: "",
  labelClassName: "",
  rowClassName: "",
  valueClassName: "",
};

export default CustomTable;
