import React from "react";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full max-w-screen-lg relative mx-auto">
      <div className="w-full flex flex-row align-center justify-between mb-5 mr-5">
        <h1 className={`pl-15 py-2 page-title--about text-2xl text-purple-200 font-normal`}>
          {t(`labels.about`)}
        </h1>
      </div>
      <div className="w-full px-15 pb-15" dangerouslySetInnerHTML={{ __html: t("app.about") }} />
    </div>
  );
};

export default About;
