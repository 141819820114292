import _ from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import RecommendationIcon from "../common/RecommendationIcon";
import { useTranslation } from "react-i18next";
import { recommendationDirectionShape } from "../../lib/data_shapes";
import Box from "../common/Box";

const RecommendationStrengthContent = ({
  className,
  gradeStrength,
  recommendationDirection,
  withIcon,
  textClassName,
}) => {
  const { t } = useTranslation("");

  const recommendationType = useMemo(() => {
    // WiP, Bart need to be discuss with others how to handle different cases
    switch (recommendationDirection) {
      case "positive":
        switch (gradeStrength) {
          case "strong":
            return "strong-for";
          case "conditional":
            return "suggest-for";
          default:
            return "";
        }
      case "negative":
        switch (gradeStrength) {
          case "strong":
            return "recommend-against";
          case "conditional":
            return "suggest-against";
          default:
            return "";
        }
      case "neutral":
        return "suggest-either";
      default:
        return "";
    }
  }, [recommendationDirection, gradeStrength]);

  const recommendationText = useMemo(() => {
    const strengthText = t(`recommendation.strength.${gradeStrength}`);
    const directionText = _.includes(["positive", "negative", "neutral"], recommendationDirection)
      ? t(`recommendation.recommendationDirection.${recommendationDirection}`).toLowerCase()
      : "";
    return _.includes(["not_stated", "unclear", "other_explanation_in_notes"], gradeStrength)
      ? strengthText
      : `${strengthText} ${directionText}`.trim();
  }, [t, recommendationDirection, gradeStrength]);

  return (
    <div className={className}>
      <div>{t("recommendation.recommendation_strength")}</div>
      {withIcon ? (
        <RecommendationIcon
          textClassName={textClassName}
          recommendationType={recommendationType}
          recommendationText={recommendationText}
        />
      ) : (
        <div className={textClassName}>{recommendationText}</div>
      )}
    </div>
  );
};

const RecommendationStrength = ({
  className,
  noBox,
  gradeStrength,
  recommendationDirection,
  textClassName,
  withIcon,
}) => {
  const { t } = useTranslation("");

  const recommendation = useMemo(() => {
    return (
      <RecommendationStrengthContent
        withIcon={withIcon}
        className={className}
        gradeStrength={gradeStrength}
        textClassName={textClassName}
        recommendationDirection={recommendationDirection}
      />
    );
  }, [className, gradeStrength, recommendationDirection, withIcon, textClassName]);

  return gradeStrength ? (
    noBox ? (
      recommendation
    ) : (
      <Box
        tooltip={t("tooltips.strength")}
        className="mb-1 text-left mr-2 flex-grow-0"
        contentClassName="text-sm font-normal"
        label={null}
      >
        {recommendation}
      </Box>
    )
  ) : null;
};

RecommendationStrength.propTypes = {
  className: PropTypes.string,
  noBox: PropTypes.bool,
  gradeStrength: PropTypes.string,
  recommendationDirection: recommendationDirectionShape,
  textClassName: PropTypes.string,
  withIcon: PropTypes.bool,
};

RecommendationStrength.defaultProps = {
  className: "",
  noBox: false,
  gradeStrength: null,
  textClassName: "",
  recommendationDirection: null,
  withIcon: false,
};

export default RecommendationStrength;
