import React from "react";
import PropTypes from "prop-types";

const GridRow = ({ children, className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
};

GridRow.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

GridRow.defaultProps = {
  className: "",
};

export default GridRow;
