import React, { useCallback, useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import CustomTable from "../common/CustomTable";
import { useTranslation } from "react-i18next";
import ExpandableCard from "../common/ExpandableCard";

const keysToSkip = ["@id", "displayId", "shortTitle", "title"];

const SourceOfRecommendation = ({ guideline }) => {
  const { t } = useTranslation();
  const getValue = useCallback(
    (key, value) => {
      switch (key) {
        case "intent":
          return _.reduce(
            value,
            (acc, v) => (v === "all" ? acc : acc.concat(t(`recommendation.intents.${v}`))),
            []
          ).join(", ");

        default:
          return value?.toString();
      }
    },
    [t]
  );

  const rows = useMemo(
    () =>
      _.reduce(
        guideline,
        (acc, value, label) => {
          const v = getValue(label, value);
          return keysToSkip.indexOf(label) !== -1 || _.isEmpty(v?.toString())
            ? acc
            : acc.concat({ label: t(`recommendation.${label}`), value: v });
        },
        []
      ),
    [guideline, getValue, t]
  );

  return (
    guideline && (
      <ExpandableCard title={t("labels.source_of_recommendation")}>
        <h2 className="font-semibold m-3">{guideline.title}</h2>
        <CustomTable rows={rows} />
      </ExpandableCard>
    )
  );
};

SourceOfRecommendation.propTypes = {
  guideline: PropTypes.shape({
    citation: PropTypes.string,
    publicationYear: PropTypes.number,
    title: PropTypes.string.isRequired,
    shortTitle: PropTypes.string,
    isbn: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default SourceOfRecommendation;
