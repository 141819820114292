import React from "react";
import PropTypes from "prop-types";

const Box = ({ className, contentClassName, label, children, tooltip }) => {
  return (
    <div
      data-tip={tooltip}
      className={`px-3 py-2 border-blue-800 border rounded-lg bg-white font-catamaran ${className}`}
    >
      {label && <div className="text-blue-800 text-sm">{label}</div>}
      <div className={`text-blue-800 ${contentClassName}`}>{children}</div>
    </div>
  );
};

Box.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.node,
};

Box.defaultProps = {
  className: "",
  contentClassName: "",
  label: null,
  tooltip: null,
};

export default Box;
