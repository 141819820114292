import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const RecommendationIcon = ({ textClassName, recommendationType, recommendationText }) => {
  return (
    <div className="flex flex-row items-center leading-none">
      {!_.isEmpty(recommendationType) && (
        <div className={`recommendation-icon ${recommendationType}`} />
      )}
      <div className={textClassName}>{recommendationText}</div>
    </div>
  );
};

RecommendationIcon.propTypes = {
  recommendationType: PropTypes.oneOf([
    "",
    "recommend-against",
    "conditional",
    "suggest-either",
    "suggest-against",
    "strong-for",
    "suggest-for",
    "recommend-for",
  ]),
  recommendationText: PropTypes.string.isRequired,
  textClassName: PropTypes.string,
};

RecommendationIcon.defaultProps = {
  recommendationType: null,
  textClassName: "",
};

export default RecommendationIcon;
