import React from "react";
import PropTypes from "prop-types";

const GridContainer = ({ children, className }) => {
  return <div className={`grid-container ${className}`}>{children}</div>;
};

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

GridContainer.defaultProps = {
  className: ""
};

export default GridContainer;
