import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PopulationLabel = ({ group, population }) => {
  const { t } = useTranslation();
  const label = group === "populations" ? population.name : t(`groups.${group}.${population.name}`);
  return (
    <div className="population">
      <span className="population-label" data-tip={label} data-place="right">
        {label.length > 50 ? label.slice(0, 50) + "..." : label}
      </span>
      <span
        className="population-count"
        data-tip={t("labels.no_of_recommendations")}
        data-place="right"
      >
        {population.recommendationsCount}
      </span>
    </div>
  );
};

PopulationLabel.propTypes = {
  group: PropTypes.string.isRequired,
  population: PropTypes.shape({
    name: PropTypes.string.isRequired,
    recommendationsCount: PropTypes.number.isRequired,
  }).isRequired,
};

export default PopulationLabel;
