import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const Search = ({ onSearch, searchQuery, className, inputClassName }) => {
  const { t } = useTranslation();
  const clearSearch = useCallback(
    (e) => {
      e.preventDefault();
      setSearchQuery("");
      onSearch("");
    },
    [onSearch]
  );

  const [query, setSearchQuery] = useState(searchQuery || "");

  return (
    <div className={`relative ${className}`}>
      <form
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
          onSearch(query);
        }}
      >
        <input
          className={`search-input bg-transparent ${inputClassName}`}
          type="text"
          onChange={(e) => setSearchQuery(e.target.value)}
          value={query}
          placeholder={t("actions.search_in_recommendations")}
        />

        <button type="submit" className="btn-search" />
        {!_.isEmpty(query) && <button className="btn-clear-search" onClick={clearSearch} />}
      </form>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
  inputClassName: PropTypes.string,
};

Search.defaultProps = {
  searchQuery: null,
  className: "",
};

export default Search;
