import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsFetch } from "../../lib/api";
import { useSearchQuery } from "../../lib/custom_hooks";
import Sidebar from "../Sidebar";
import LargeSpinner from "../common/LargeSpinner";
import Search from "../common/Search";
import GroupFilter from "../recommendation/GroupFilter";
import RecommendationGroupsList from "../recommendation/RecommendationGroupsList";

const RecommendationsPage = () => {
  const initialSearchQuery = useSearchQuery();
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    isError,
    currentIntentSelected,
    onChangeIntentSelected,
    currentFilters,
    onChangeFilter,
    onClearFilters,
  } = useRecommendationsFetch("recommendations");

  return (
    <div className="gradient-bg overflow-hidden relative h-full">
      <div
        className="relative
          h-full w-full overflow-hidden flex flex-col-reverse sm:flex-row justify-end"
      >
        <div className="flex-grow w-full max-w-screen-lg mx-auto">
          <Search
            className="inline-block mx-3 my-6"
            searchQuery={initialSearchQuery}
            onSearch={(query) => onChangeFilter("searchQuery", query)}
          />
          <GroupFilter current={currentIntentSelected} onGroupClick={onChangeIntentSelected} />
          {isError && <div>{t("errors.fetching_failed")}</div>}
          {isLoading ? (
            <LargeSpinner />
          ) : (
            <RecommendationGroupsList
              groupName={currentIntentSelected}
              recommendationGroup={data.recommendations}
            />
          )}
        </div>
        {isLoading || _.isEmpty(data) ? null : (
          <Sidebar
            isLoading={isLoading}
            filters={data.filterValues}
            currentFilters={currentFilters}
            onChangeFilter={onChangeFilter}
            onClearFilters={onClearFilters}
          />
        )}
      </div>
    </div>
  );
};

export default RecommendationsPage;
