import React from "react";
import PropTypes from "prop-types";
import Box from "../common/Box";
import Certainty from "../common/Certainty";
import { useTranslation } from "react-i18next";

const RecommendationCertainty = ({
  noBox,
  gradeCertaintyOfEvidence,
  gradeCertaintyOfEvidenceLabel,
  labelClassName,
  signClassName,
}) => {
  const { t } = useTranslation("");

  const label = gradeCertaintyOfEvidenceLabel
    ? t(`recommendation.certainty.${gradeCertaintyOfEvidenceLabel}`)
    : t(`labels.certainty_of_evidence`);

  return gradeCertaintyOfEvidence ? (
    noBox ? (
      <div className="mt-1">
        <div className={labelClassName}>{label}</div>
        <Certainty signClassName={signClassName} value={gradeCertaintyOfEvidence} />
      </div>
    ) : (
      <Box
        tooltip={t("tooltips.certainty_of_evidence")}
        className="mb-1 text-left flex-grow-0"
        label={label}
      >
        <div className="mt-1">
          <Certainty signClassName={signClassName} value={gradeCertaintyOfEvidence} />
        </div>
      </Box>
    )
  ) : null;
};

RecommendationCertainty.propTypes = {
  noBox: PropTypes.bool,
  labelClassName: PropTypes.string,
  gradeCertaintyOfEvidenceLabel: PropTypes.string,
  gradeCertaintyOfEvidence: PropTypes.string,
  signClassName: PropTypes.string,
};

RecommendationCertainty.defaultProps = {
  noBox: false,
  labelClassName: "",
  gradeCertaintyOfEvidence: null,
  gradeCertaintyOfEvidenceLabel: "",
  signClassName: "",
};

export default RecommendationCertainty;
