import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import RecommendationItem from "./RecommendationItem";
import { groups } from "./GroupFilter";

const RecommendationGroupsList = ({ recommendationGroup }) => {
  const { t } = useTranslation();

  return (
    <div className="mx-2 mt-12 pt-0 md:-mt-5 md:pt-5">
      <div className="recommendation-list-container max-w-screen-lg mt-0  pt-0 md:-mt-5 md:pt-5">
        <ul className="recommendation-list mt-0 mr-0 md:mr-5 md:mt-2">
          {recommendationGroup ? (
            <li>
              <div className="bg-transparent pb-1">
                <div className="mb-1">
                  {map(recommendationGroup, (recommendation, idx) => (
                    <RecommendationItem
                      key={`${idx}-${recommendation["@id"]}`}
                      recommendation={recommendation}
                    />
                  ))}
                </div>
              </div>
            </li>
          ) : (
            <div className="bg-white shadow mb-1 p-5 lg:mr-3 text-blue-800">
              {t("errors.no_results")}
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

RecommendationGroupsList.propTypes = {
  groupName: PropTypes.string.isRequired,
  recommendationGroup: PropTypes.shape({
    name: PropTypes.oneOf(groups).isRequired,
    count: PropTypes.number.isRequired,
    tbTypes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        recommendations: PropTypes.array.isRequired,
      })
    ),
  }),
};

export default RecommendationGroupsList;
