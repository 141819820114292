import React from "react";
import PropTypes from "prop-types";

const GridCell = ({ children, className }) => {
  return <div className={`grid-cell ${className}`}>{children}</div>;
};

GridCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

GridCell.defaultProps = {
  className: "",
  children: null,
};

export default GridCell;
