import { chain, isEmpty, map, capitalize } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { interventionShape, populationShape, questionShape } from "../../lib/data_shapes";
import Card from "../common/Card";
import CustomTable from "../common/CustomTable";
import RecommendationCertainty from "../recommendation/RecommendationCertainty";
import RecommendationStrength from "../recommendation/RecommendationStrength";

const AGE_ORDER = ["infant", "child", "adolescent", "adult", "unspecified"];

const Populations = ({ populations }) =>
  map(populations, (population) => <div key={population["@id"]}>{population.description}</div>);

const Interventions = ({ interventions }) =>
  map(interventions, (intervention) => <div key={intervention["@id"]}>{intervention.name}</div>);

const mapIdsToValues = (ids, values) =>
  chain(ids)
    .map((id) => values.find((v) => v["@id"] === id["@id"]))
    .compact()
    .value();

const getAdditionalRows = (question, t) =>
  chain(["evidenceProfile", "etd", "evidenceSynthesis"])
    .map((key) => {
      if (!isEmpty(question[`${key}Reference`])) {
        return {
          label: t(`labels.${key}`),
          value: (
            <a
              className="external-link"
              href={question[`${key}Reference`]}
              target="_blank"
              rel="noreferrer noopener"
            >
              {!isEmpty(question[`${key}Pages`])
                ? t("labels.see_pages", { pages: question[`${key}Pages`] })
                : question[`${key}Reference`]}
            </a>
          ),
        };
      }
      return null;
    })
    .compact()
    .value();

const QuestionData = ({ commonStrengthAndCertainty, question, interventions, populations }) => {
  const { t } = useTranslation();
  const questionPopulations = useMemo(() => mapIdsToValues(question.population, populations), [
    question.population,
    populations,
  ]);
  const questionInterventions = useMemo(
    () => mapIdsToValues(question.intervention, interventions),
    [question.intervention, interventions]
  );

  const ages = useMemo(
    () =>
      chain(questionPopulations)
        .map("ages")
        .map("value")
        .flatten()
        .uniq()
        .sort((age1, age2) => AGE_ORDER.indexOf(age1) - AGE_ORDER.indexOf(age2))
        .map((age) => t(`recommendation.ages.${age.toLowerCase()}`))
        .join(", ")
        .value(),
    [t, questionPopulations]
  );

  const additionalRows = useMemo(() => getAdditionalRows(question, t), [question, t]);
  const {
    gradeStrength,
    gradeCertaintyOfEvidence,
    gradeCertaintyOfEvidenceLabel,
    recommendationDirection,
  } = question;

  return (
    <Card
      className={`question-data bg-gray-500 my-5 ${
        !commonStrengthAndCertainty ? "m-5 px-0" : ""
      } shadow-none`}
    >
      <div className="flex flex-row">
        <CustomTable
          className="flex-grow"
          rows={[
            {
              label: t("recommendation.population"),
              value: <Populations populations={questionPopulations} />,
            },
            {
              label: t("recommendation.age"),
              value: capitalize(ages),
            },
            {
              label: t("recommendation.intervention"),
              value: <Interventions interventions={questionInterventions} />,
            },
          ]}
        />
        {!commonStrengthAndCertainty && (
          <div className="w-56 flex-shrink-0">
            <RecommendationStrength
              gradeStrength={gradeStrength}
              recommendationDirection={recommendationDirection}
            />
            <RecommendationCertainty
              gradeCertaintyOfEvidence={gradeCertaintyOfEvidence}
              gradeCertaintyOfEvidenceLabel={gradeCertaintyOfEvidenceLabel}
            />
          </div>
        )}
      </div>
      {!isEmpty(additionalRows) && (
        <div className="flex flex-row mt-8">
          <CustomTable className="flex-grow" rows={additionalRows} />
        </div>
      )}
    </Card>
  );
};

QuestionData.propTypes = {
  commonStrengthAndCertainty: PropTypes.bool.isRequired,
  population: populationShape,
  intervention: interventionShape,
  question: questionShape,
};

export default QuestionData;
