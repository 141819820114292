import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const GuidelineLink = ({ guideline }) => {
  const { t } = useTranslation();
  const { link, title } = guideline;
  return (
    <div className="flex flex-row mb-2">
      <span className="mr-1 text-gray-800">{t("recommendations.guideline")}</span>
      <a href={link} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    </div>
  );
};

GuidelineLink.propTypes = {
  guideline: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default GuidelineLink;
