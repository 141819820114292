import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import GuidelineLink from "../common/GuidelineLink";

const RecommendationItem = ({ recommendation }) => {
  const { guideline, recommendation: text } = recommendation;
  return (
    <div className="recommendation-item shadow bg-white mb-4 last:mb-0 hover:bg-blue-1100 hover:shadow">
      <Link className="text-black block p-4" to={`/recommendation/${recommendation["@id"]}`}>
        <span className="block text-sm font-raleway">
          {guideline && <GuidelineLink guideline={guideline} />}
          <span className="font-semibold" dangerouslySetInnerHTML={{ __html: text }} />
        </span>
      </Link>
    </div>
  );
};

RecommendationItem.propTypes = {
  recommendation: PropTypes.shape({
    "@id": PropTypes.string.isRequired,
    recommendation: PropTypes.string.isRequired,
    guideline: PropTypes.shape({
      link: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
    ages: PropTypes.arrayOf(PropTypes.string).isRequired,
    gradeCertaintyOfEvidence: PropTypes.string,
    gradeStrength: PropTypes.string,
  }).isRequired,
};

export default RecommendationItem;
