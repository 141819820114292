import { chain, first, map, snakeCase, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { noteShape, referenceShape } from "../../lib/data_shapes";
import CustomTable from "../common/CustomTable";
import ExpandableCard from "../common/ExpandableCard";
import References from "./References";

const Notes = ({ notes, references }) => {
  const { t } = useTranslation();
  const rows = chain(notes)
    .groupBy("@type")
    .map(n => ({
      label: t(`labels.${snakeCase(first(n)["@type"])}`),
      value: map(n, (v, idx) => (
        <div className="mb-2" key={idx} dangerouslySetInnerHTML={{ __html: v.value }} />
      ))
    }))
    .value();

  return isEmpty(rows) && isEmpty(references) ? null : (
    <ExpandableCard className="mt-5" title={t("labels.notes")}>
      <CustomTable rows={rows} />
      <References references={references} />
    </ExpandableCard>
  );
};

Notes.propTypes = {
  notes: PropTypes.arrayOf(noteShape).isRequired,
  references: PropTypes.arrayOf(referenceShape).isRequired
};

export default Notes;
