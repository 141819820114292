import React from "react";
import { map, isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Remarks = ({ remarks }) => {
  const { t } = useTranslation();
  return (
    <div>
      {!isEmpty(remarks) && (
        <div className="p-4">
          <h2 className="font-semibold uppercase text-blue-400">{t("labels.remarks")}</h2>
          {map(remarks, (remark, idx) => (
            <div
              className="p-2 text-gray-300 text-sm"
              key={idx}
              dangerouslySetInnerHTML={{ __html: remark }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

Remarks.propTypes = {
  remarks: PropTypes.arrayOf(PropTypes.string)
};

Remarks.defaultProps = {
  remarks: []
};

export default Remarks;
