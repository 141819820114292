import React from "react";
import PropTypes from "prop-types";

const RMTGroup = ({ children, className }) => {
  return <div className={`group-container ${className}`}>{children}</div>;
};

RMTGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

RMTGroup.defaultProps = {
  className: ""
};

export default RMTGroup;
