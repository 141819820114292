import PropTypes from "prop-types";

export const certaintyValueShape = PropTypes.oneOf([
  "low",
  "very_low",
  "moderate",
  "high",
  "other_explanation_in_notes",
  "not_stated",
  "unclear",
  "very_low_to_low",
  "low_to_moderate",
  "moderate_to_high",
]);

export const recommendationDirectionShape = PropTypes.oneOf([
  "positive",
  "negative",
  "neutral",
  "unclear",
  "not_stated",
]);

export const questionShape = PropTypes.shape({
  "@id": PropTypes.string.isRequired,
  "@type": PropTypes.string.isRequired,
  gradeCertaintyOfEvidence: PropTypes.string.isRequired,
  gradeStrength: PropTypes.string.isRequired,
  intervention: PropTypes.arrayOf(
    PropTypes.shape({
      "@id": PropTypes.string.isRequired,
    })
  ),
  population: PropTypes.arrayOf(
    PropTypes.shape({
      "@id": PropTypes.string.isRequired,
    })
  ),
  recommendationIntents: PropTypes.arrayOf(PropTypes.string),
  recommendationDirection: PropTypes.string,
});

export const populationShape = PropTypes.shape({
  "@id": PropTypes.string.isRequired,
  "@type": PropTypes.string.isRequired,
  ages: PropTypes.shape({
    value: PropTypes.array,
  }).isRequired,
  condition: PropTypes.array,
  description: PropTypes.string.isRequired,
});

export const interventionShape = PropTypes.shape({
  "@id": PropTypes.string.isRequired,
  "@type": PropTypes.string.isRequired,
  code: PropTypes.array,
  name: PropTypes.string.isRequired,
});

export const guidelineShape = PropTypes.shape({
  "@id": PropTypes.string.isRequired,
  citation: PropTypes.string.isRequired,
  displayId: PropTypes.string.isRequired,
  isbn: PropTypes.string,
  publicationYear: PropTypes.number,
  shortTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
});

export const referenceShape = PropTypes.shape({
  "@id": PropTypes.number.isRequired,
  value: PropTypes.string,
});

export const noteShape = PropTypes.shape({
  "@type": PropTypes.string.isRequired,
  reference: PropTypes.arrayOf(PropTypes.shape({ "@id": PropTypes.number.isRequired })),
  value: PropTypes.string,
});
