import React, { useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { certaintyValueShape } from "../../lib/data_shapes";

const totalCircles = 4;
const plusCirclesMap = {
  not_stated: 0,
  unclear: 0,
  other_explanation_in_notes: 0,
  very_low: 1,
  low: 2,
  moderate: 3,
  high: 4,
};

const CUSTOM_CERTAINTIES = ["very_low_to_low", "low_to_moderate", "moderate_to_high"];

const Circles = ({ value, className, signClassName }) => {
  const plusesNum = plusCirclesMap[value];

  const signClassNames = `quality-sign ${signClassName}`;

  const signs = useMemo(() => {
    return Array(totalCircles)
      .fill("")
      .map((_elem, idx) =>
        idx < plusesNum ? (
          <span key={idx} className={signClassNames}>
            &#x2a01;
          </span>
        ) : (
          <span key={idx} className={signClassNames}>
            &#x25ef;
          </span>
        )
      );
  }, [plusesNum, signClassNames]);

  return (
    <div style={{ height: "15px" }} className={`whitespace-no-wrap ${className}`}>
      {signs}
    </div>
  );
};

Circles.propTypes = {
  value: certaintyValueShape,
  className: PropTypes.string,
  signClassName: PropTypes.string,
};

Circles.defaultProps = {
  className: "",
  signClassName: "",
};

const CertaintyCircles = ({ value, signClassName }) => {
  if (_.isEmpty(value)) {
    return null;
  }
  if (_.includes(CUSTOM_CERTAINTIES, value)) {
    return (
      <div>
        {_.map(value.split("_to_"), (v) => (
          <Circles signClassName={signClassName} key={v} className="my-1" value={v} />
        ))}
      </div>
    );
  } else {
    return <Circles signClassName={signClassName} value={value} />;
  }
};

CertaintyCircles.propTypes = {
  value: certaintyValueShape,
  signClassName: PropTypes.string,
};

CertaintyCircles.defaultProps = {
  value: null,
  signClassName: "",
};

export default CertaintyCircles;
