import _ from "lodash";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import EpLogo from "../../assets/ep-logo.svg";
import GradeProLogo from "../../assets/gradepro-logo.svg";
import McMasterLogo from "../../assets/mcmaster-logo.svg";
import { twitterLink, glossaryLink } from "../../lib/constants";
import { useHandleClickOutside } from "../../lib/custom_hooks";

const HamburgerMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useHandleClickOutside(menuRef, () => setMenuOpen(false));

  const goTo = (page) => () => {
    history.push(page);
    setMenuOpen(false);
  };

  return (
    <div ref={menuRef} className="h-6 relative ml-auto mt-1 self-start justify-end hamburger-menu">
      <button
        className="open-menu block"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <span className="block pt-1 mb-1 px-3 w-full bg-yellow-700" />
        <span className="block pt-1 mb-1 px-3 w-full bg-yellow-700" />
        <span className="block pt-1 mb-1 px-3 w-full bg-yellow-700" />
      </button>

      {menuOpen && (
        <div className="menu-container">
          <div className="flex flex-col h-full">
            <div className="flex flex-col flex-grow">
              <h1 className={`px-5 pb-2 text-purple-200 font-normal`}>
                {t(`app.aboutEtbGuidelines`)}
              </h1>
              <div
                className="w-full px-5 pb-2 text-sm"
                dangerouslySetInnerHTML={{
                  __html: t("app.aboutShort"),
                }}
              />
              <div className="text-right pr-6 pb-4">
                <button onClick={goTo("/about")} className="see_more" to="/about">
                  {t("labels.read_more")}
                </button>
              </div>

              <div className="py-5 mx-5 glossary-links">
                <button
                  className="block py-2 font-semibold"
                  onClick={goTo("/glossary")}
                  to="/glossary"
                >
                  {t("labels.glossary")}
                </button>
                <a
                  className="block py-2 font-semibold"
                  href={glossaryLink}
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {t("labels.grade_handbook_glossary")}
                </a>
              </div>

              {!_.isEmpty(twitterLink) && (
                <div className="py-5 mx-5">
                  <a
                    className="social-link twitter"
                    href={twitterLink}
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    <span className="logo" />
                    {t("labels.visit_our_twitter")}
                  </a>
                </div>
              )}
              <div className="logos flex flex-col flex-grow justify-end">
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href="https://www.mcmaster.ca/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={McMasterLogo} alt="McMaster" />
                </a>
                <a
                  className="block mb-12 flex justify-end mr-6"
                  href="https://evidenceprime.com/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={EpLogo} alt="EvidencePrime" />
                </a>
                <a
                  className="block mb-6 flex justify-end mr-6"
                  href="https://gradepro.org/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  <img src={GradeProLogo} alt="GRADEpro" />
                </a>
              </div>
            </div>

            <div className="text-center sm:text-left">
              <button className="close-menu-arrow" onClick={() => setMenuOpen(false)} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HamburgerMenu.propTypes = {};

export default HamburgerMenu;
