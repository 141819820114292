import React, { useState } from "react";
import Modal from "./Modal";
import { useInterval } from "../../lib/custom_hooks";

interface IFakeLoader {
  isLoading: boolean;
  interval: number;
}

const FakeLoader = ({ isLoading, interval }: IFakeLoader) => {
  return (
    <Modal isOpen={isLoading} onClose={() => null}>
      <FakeLoaderInner interval={interval} />
    </Modal>
  );
};

const FakeLoaderInner = ({ interval }: { interval: number }) => {
  const [width, setWidth] = useState(0);

  useInterval(() => {
    if (width < 95) setWidth((width) => (width += 1));
    if (width > 50) {
      document.getElementById("fake-loader__progress-status")?.style.setProperty("color", "#fff");
    }
  }, interval);

  return (
    <div id="fake-loader">
      <div id="fake-loader__progress-bar" style={{ width: `${width}%` }} />
      <div id="fake-loader__progress-status">{width}%</div>
    </div>
  );
};

export default FakeLoader;
