import React, { useState } from "react";
import { DynamicHeightContainer } from "@ep/react-components";
import PropTypes from "prop-types";

const ExpandableCard = ({ className, children, title }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <DynamicHeightContainer className={className}>
      <div
        className={`
          bg-blue-800
          cursor-pointer
          expand-toggle
          expandable-card
          hover:opacity-75
          px-3 
          py-2
          relative
          text-white
        ${expanded ? "expanded" : "collapsed"}`}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
      </div>
      {expanded && (
        <div
          className="
          bg-gray-500
          expandable-card__content
          px-2
          py-2
          "
        >
          {children}
        </div>
      )}
    </DynamicHeightContainer>
  );
};

ExpandableCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

ExpandableCard.defaultProps = {
  className: ""
};

export default ExpandableCard;
