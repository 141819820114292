import { map, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getFilterOptionName } from "../lib/helpers";
import FilterSelect from "./common/FilterSelect";

const ClearFiltersBtn = ({ onClearFilters, className }) => {
  const { t } = useTranslation();
  return (
    <button
      className={`
        border
        rounded-full
        border-white
        hover:opacity-75
        text-white
        leading-none
        h-6
        mr-3
        py-0
        px-3
        bg-blue-200
        ${className}`}
      onClick={onClearFilters}
    >
      {t("actions.clear")}
    </button>
  );
};

const Sidebar = ({
  expandable,
  filters,
  isLoading,
  onChangeFilter,
  currentFilters,
  onClearFilters,
  className,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const { t } = useTranslation();

  const buttonClasses = `text-white uppercase bg-transparent md:bg-blue-200 py-1 px-2 block sidebar-toggle rounded ${
    expandable ? "" : "lg:hidden absolute"
  }
  ${sidebarOpened ? "expanded" : "collapsed"}
`;

  const sidebarClasses = expandable
    ? `sidebar shadow-md h-8 p-0 w-80 overflow-visible 
      ${sidebarOpened ? "expanded" : "collapsed"} ${className} ${expandable ? "expandable" : ""}`
    : `sidebar shadow-md h-8 w-full md:w-60 md:p-0 lg:w-80 lg:relative overflow-hidden md:overflow-visible ${
        sidebarOpened ? "expanded" : "collapsed"
      } ${className}`;

  return (
    <aside className={sidebarClasses}>
      <div className="flex flex-col sidebar-content">
        <div className="flex-grow">
          {!isLoading && (
            <>
              <button className={buttonClasses} onClick={() => setSidebarOpened(!sidebarOpened)}>
                {t("labels.filters")}
              </button>
              <div className="flex flex-row align-center">
                <div className="flex-grow text-white uppercase p-3">
                  {!expandable && <span className="hidden lg:block">{t("labels.filters")}</span>}
                </div>
                {!isEmpty(currentFilters) && (
                  <ClearFiltersBtn
                    onClearFilters={onClearFilters}
                    className={`hidden md:block ${expandable ? "-mt-8" : ""}`}
                  />
                )}
              </div>
              <div className="py-3 px-3 md:py-3 md:px-3">
                <div>
                  {map(filters, (options, key) => (
                    <FilterSelect
                      key={key}
                      label={t(`filters.labels.${key}`)}
                      selected={currentFilters[key]}
                      options={map([{ name: "any", count: 0 }].concat(options), (option) => ({
                        text: getFilterOptionName(t, key, option),
                        value: option.name,
                      }))}
                      onChange={(value) => onChangeFilter(key, value)}
                    />
                  ))}
                </div>
              </div>
            </>
          )}
          <div className="flex flex-row justify-end">
            {!isEmpty(currentFilters) && (
              <ClearFiltersBtn onClearFilters={onClearFilters} className="block md:hidden" />
            )}
          </div>
        </div>
        <button
          className="close-filter-sidebar block md:hidden"
          onClick={() => setSidebarOpened(false)}
        />
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  expandable: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  currentFilters: PropTypes.object,
  onClearFilters: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
  filters: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
      })
    )
  ),
};

Sidebar.defaultProps = {
  expandable: false,
  className: "",
};

export default Sidebar;
