import { CustomSelectPopover as SelectCustom } from "@ep/react-components";

import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Select = ({ className, listClassName, onChange, options, selected }) => {
  const { t } = useTranslation();
  return (
    <SelectCustom
      className={`block w-full bg-gray-1000 text-gray-300 ${className}`}
      listClassName={`p-3 bg-gray-1000 text-gray-300 cursor-pointer select-options ${listClassName}`}
      onChange={onChange}
      options={options}
      orderedList={false}
      selected={selected}
      signPosition="left"
      i18n={t}
    />
  );
};

Select.propTypes = {
  className: PropTypes.string,
  listClassName: PropTypes.string,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
};

Select.defaultProps = {
  className: "",
  listClassName: "",
  selected: null
};

export default Select;
