import { map } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import GridCell from "../grid/GridCell";
import RMTPill from "./RMTPill";

const RMTRow = ({ columns, displayMode, colorMap, highlightedRecommendation }) =>
  map(columns, (column, k) => {
    return (
      <GridCell key={k}>
        <RMTPill
          highlightedRecommendation={highlightedRecommendation}
          displayMode={displayMode}
          colorMap={colorMap}
          column={column}
        />
      </GridCell>
    );
  });

RMTRow.propTypes = {
  displayMode: PropTypes.oneOf(["normal", "heatMap"]).isRequired,
  colorMap: PropTypes.arrayOf(PropTypes.string).isRequired,
  highlightedRecommendation: PropTypes.string.isRequired,
};

export default RMTRow;
