import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RecommendationCertainty from "../recommendation/RecommendationCertainty";
import RecommendationStrength from "../recommendation/RecommendationStrength";

const RecommendationsComparison = ({
  recommendationsToCompare,
  highlightedRecommendation,
  highlightRecommendation,
}) => {
  const { t } = useTranslation();

  return (
    <ul className="recommendations-to-compare">
      {_.map(recommendationsToCompare, (rec) => (
        <li
          key={rec["@id"]}
          className={`flex flex-row pb-4 pt-2 px-2 mb-4 
          ${highlightedRecommendation === rec["@id"] ? "highlighted" : ""}`}
        >
          <div className="flex-grow mr-4">
            <Link
              to={`/recommendation/${rec["@id"]}`}
              className="hover:opacity-75"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="mb-2" dangerouslySetInnerHTML={{ __html: rec.recommendation }}></div>
            </Link>
            <div className="flex font-raleway text-xs text-gray-450">
              <div className="flex-grow">
                <RecommendationCertainty
                  noBox
                  gradeCertaintyOfEvidence={rec.gradeCertaintyOfEvidence}
                  gradeCertaintyOfEvidenceLabel={rec.gradeCertaintyOfEvidenceLabel}
                  signClassName="quality-sign--yellow"
                />
              </div>
              <div>
                <RecommendationStrength
                  className="mb-2 mt-1"
                  noBox
                  withIcon
                  gradeStrength={rec.gradeStrength}
                  recommendationDirection={rec.recommendationDirection}
                />
              </div>
            </div>
            {!_.isEmpty(rec.guidelineCitation) && (
              <div className="text-xs">
                <div className="font-semibold mt-3">{t("labels.source_of_recommendation")}</div>
                <div>{rec.guidelineCitation}</div>
              </div>
            )}
          </div>
          <div className="highlight-container">
            <button
              className={`btn ${highlightedRecommendation === rec["@id"] ? "highlight" : ""}`}
              onClick={() =>
                highlightRecommendation(highlightedRecommendation === rec["@id"] ? "" : rec["@id"])
              }
            >
              {highlightedRecommendation === rec["@id"]
                ? t("labels.highlighted")
                : t("actions.highlight")}
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

RecommendationsComparison.propTypes = {
  recommendationsToCompare: PropTypes.arrayOf(
    PropTypes.shape({
      "@id": PropTypes.string.isRequired,
      recommendation: PropTypes.string.isRequired,
      guidelineCitation: PropTypes.string,
    }).isRequired
  ).isRequired,
  highlightedRecommendation: PropTypes.string.isRequired,
  highlightRecommendation: PropTypes.func.isRequired,
};

export default RecommendationsComparison;
